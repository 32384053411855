<!--TODO:跳转张永华，经济分析系统，页面，统一登录入口-->
<template>
  <div>

  </div>

</template>

<script>
import {ElMessage} from "element-plus";
import { mapMutations } from 'vuex'
import {Burger} from "@element-plus/icons";
export default {
  name: "transfer",
  components: {Burger},
  created() {
    const url = window.location.href;             //获取当前url
    const dz_url = url.split('#')[1];    //获取#/之前的字符串
    const cs = dz_url.split('?')[1];     //获取?之后的参数字符串
    const jtcs = cs.split("&");
    if (cs === undefined){
      ElMessage.error('状态有误，请联系管理员!');
    }else {
      const token = jtcs[0].split("=")[1];
      this.SET_TOKEN(token);
      this.axios.post("/userinfo/getuserjj", (response) => {
        console.log(response)
        this.SET_USER(response.obj.name)
        this.SET_BDST(response.obj.bdst);
        this.SET_DEPART(response.obj.depart);

      })
      let v = jtcs[1].split("=")[1];
      let v2 = v.slice(2);
      let v3 = v.slice(1, 2).toUpperCase();
      let v4 = v3 + v2;
      const time = Date.parse(new Date()) + this.uuid();
      this.$router.push({
        name: v4,
        params: {
          key: time,
        }
      });
    }
  },
  methods:{
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations(['SET_USER']),
    ...mapMutations(['SET_USERID']),
    ...mapMutations(['SET_BDST']),
    ...mapMutations(['SET_DEPART']),
    ...mapMutations(['SET_POSION']),
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations(['SET_DEPARTFLAG']),
    uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4';
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';

      this.uuidA = s.join('');
      return this.uuidA;
    },
  }
}
</script>

<style scoped>

</style>